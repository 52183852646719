<template>
  <Card title="修改版本">
    <div class="form">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: detail.name,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="路径">
              <a-input
                v-decorator="[
                  'url',
                  {
                    initialValue: detail.url,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-radio-group
                v-decorator="[
                  'platformType',
                  {
                    initialValue: detail.platformType,
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-radio
                  v-for="item in [
                    {
                      name: 'android',
                      value: 'android',
                    },
                    {
                      name: 'ios',
                      value: 'ios',
                    },
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </Card>
</template>

<script>
import request from "@/api/request";

function edit(data) {
  return request({
    url: "/basic-service/softVersion/update",
    method: "post",
    data,
  });
}

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      detail: {},
    };
  },

  mounted() {
    const str = window.localStorage.getItem("version-detail");
    if (str) {
      this.detail = JSON.parse(str);
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            ...this.detail,
            ...values,
          }).then(() => {
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>